/* Section Hero */
.hero {
  background: url('../public/background-1.webp') center center/cover no-repeat;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute; /* Fixe le slider en haut */
  top: 0;
  left: 225px; /* Aligne le slider avec la barre latérale */
  width: calc(100% - 225px); /* Ajuste la largeur pour ne pas dépasser la largeur du conteneur principal */
  z-index: 500; /* Assure que le slider reste au-dessus du contenu mais en dessous des éléments de haut niveau */
}

/* Slider */
.slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}

.slide {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(.7);
}

/* Navigation Buttons */
button.prev, button.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
  padding: 10px 20px;
  border-radius: 50%;
  transition: background-color 0.3s ease, color 0.3s ease;
}

button.prev:hover, button.next:hover {
  background-color: rgba(0, 0, 0, 1);
  color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.hero-content-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20%;
  height: 285px;
}

/* Contenu Hero */
.hero-content {
  text-align: center;
  z-index: 1001;
  max-height: 110px;
}

.hero-content h1 {
  font-family: 'Georgia', serif;
  font-weight: bold;
  margin: 0;
  font-size: 48px;
  background: rgba(2, 13, 44, 0.575);
  padding: 20px 30px;
  
}

.under-hero {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 1001;
}

.under-hero p {
  font-family: 'Georgia', serif;
  font-size: 26px;
  margin-top: 5px;
  margin-bottom: 30px;
}

.buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.buttons button {
  font-size: 20px;
  font-family: 'Georgia', serif;
  text-transform: uppercase;
  padding: 8px 10px;
  border: 2px solid #ffffff;
  cursor: pointer;
  border-radius: 0;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  background-color: rgba(255, 255, 255, 1);
  border-color: #ffffff;
  a {
  color: rgba(2, 13, 44, 1);
  text-decoration: none;
  }
}

.buttons button:hover {
  background-color: transparent;
  border-color: #ffffff;
  a {
    color: white;
    text-decoration: none;
    }
}

/* Section Restaurant */
.restaurant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-family: 'Cambria', serif;
}

.restaurant-text {
  flex: 1;
  padding: 30px 20px;
  background-image: url('../public/linden-bg.webp');
  background-size: inherit;
  background-position: center;
  background-repeat: repeat;
}

.restaurant-image {
  flex: 1;
  display: flex;
  justify-content: center;
  padding-left: 20px;
}

.restaurant h3 {
  text-align: center;
  font-size: 48px;
  margin-top: 0px;
  color: #2d3245;
}

.restaurant h2 {
  color: #777777;
  font-size: 20px;
  text-align: center;
}

.restaurant p {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 15px;
  color: #2d3245;
  max-width: 80%;
  margin-left: 5rem;
}

.dish-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

/* Section Hôtel */
.hotel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-family: 'Cambria', serif;
  margin-top: -20px;
}

.hotel-text {
  flex: 1;
  padding: 30px 20px;
  background-image: url('../public/linden-bg.webp');
  background-size: inherit;
  background-position: center;
  background-repeat: repeat;
}

.hotel-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.hotel h3 {
  font-size: 48px;
  margin-top: 0px;
  text-align: center;
  color: #2d3245;
}

.hotel h2 {
  color: #777777;
  font-size: 20px;
  text-align: center;
}

.hotel p {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 15px;
  margin-left: 5rem;
  color: #2d3245;
  max-width: 80%;
}

.hotel-image-content {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

/* Styles Responsives */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .contact-info {
    max-width: 100%;
    position: relative;
    bottom: auto;
    padding: 10px;
  }

  .hero {
    background: url('../public/background-1.webp') center center/cover no-repeat;
    height: 375px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    top: 0;
    left: 180px; /* Ajuste le slider pour tenir compte de la largeur de la sidebar */
    width: calc(100% - 180px); /* Ajuste la largeur pour s'adapter à l'espace disponible */
    z-index: 500; /* Assure que le slider reste au-dessus du contenu */
  }

  .hero-content h1 {
    font-size: 30px;
    padding: 15px 20px;
    max-width: 85%;
    margin: auto;
  }

  .under-hero p {
    font-size: 22px;
  }

  .buttons button {
    font-size: 18px;
    padding: 6px 8px;
    a {
      text-decoration: none;
    }
  }

  .restaurant {
    flex-direction: column;
  }

  .restaurant-text {
    padding-right: 20px;
    text-align: center;
  }

  .restaurant h3 {
    margin-left: 0;
    line-height: 48px;
  }

  .restaurant h2 {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .restaurant p {
    max-width: 100%;
    margin-left: 0;
  }

  .dish-image {
    justify-content: center;
    margin-top: 0px;
    padding: 0;
  }

  .hotel {
    flex-direction: column-reverse;
  }

  .hotel-text {
    padding-right: 20px;
    text-align: center;
  }

  .hotel h3 {
    margin-left: 0;
    line-height: 48px;
  }

  .hotel h2 {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .hotel p {
    max-width: 100%;
    margin-left: 0;
  }

  .hotel-image-content {
    margin-top: 0px;
    padding: 0;
  }

  .hero {
    left: 0;
    width: 100%;
  }

  .slider {
    width: 100%; /* Slider occupe toute la largeur sur mobile */
    height: 100%;
    position: relative;
  }

  .hero-content,
  .under-hero {
    top: 40%;
  }
}
