.reservation-section {
    background-color: #f9f9f9;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .reservation-form-wrapper {
    background-color: white;
    padding: 0 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
  }
  
  .reservation-form h1 {
    text-align: center;
    font-family: 'Georgia', serif;
    font-size: 32px;
    margin-bottom: 20px;
    color: #2d3245;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-family: 'Cambria', serif;
    font-size: 18px;
    margin-bottom: 10px;
    color: #2d3245;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-family: 'Cambria', serif;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  .form-group textarea {
    height: 100px;
    resize: none;
  }
  
  .submit-btn {
    background-color: #2d3245;
    color: white;
    padding: 12px 20px;
    font-size: 18px;
    font-family: 'Georgia', serif;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #1d2133;
  }

  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  