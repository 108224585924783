.restaurant-page {
    padding: 50px 20px;
    font-family: 'Cambria', serif;
    color: #2d3245;
  }
  
  .restaurant-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .restaurant-header h1 {
    font-size: 48px;
    margin-bottom: 10px;
    color: #2d3245;
  }
  
  .restaurant-header p {
    font-size: 20px;
    color: #777777;
  }
  
  .restaurant-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .restaurant-text {
    flex: 1;
    max-width: 600px;
    line-height: 1.6;
  }
  
  .restaurant-text ul {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  .restaurant-text li {
    margin-bottom: 5px;
  }
  
  .restaurant-image {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .restaurant-image img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 768px) {
    .restaurant-content {
      flex-direction: column;
    }
  
    .restaurant-text {
      max-width: 100%;
      text-align: center;
    }
  
    .restaurant-image {
      margin-top: 20px;
    }
  }
  