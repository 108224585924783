/* Styles globaux ou spécifiques à App.js */
body {
  font-family: 'Cambria', serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.temporary {
  font-size: 12px;
  font-style: italic;
  color: #777777;
  margin-left: 5px;
}

.container {
  display: flex;
}

/* Style par défaut pour la sidebar en mode bureau */
.sidebar {
  width: 225px;
  background-color: #f8f8f8;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  z-index: 1000; /* Assurez-vous que la sidebar a un z-index élevé */
  transition: transform 0.3s ease; /* Transition pour le drawer */
}

/* Style du burger menu (caché par défaut en bureau) */
.burger-menu {
  display: none; /* Cacher par défaut sauf sur mobile */
  position: fixed;
  top: 15px;
  left: 15px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1100; /* Assurez-vous que le burger menu est au-dessus de la sidebar */
  width: 40px;
  height: 30px;
  flex-direction: column;
  justify-content: space-between;
}

/* Style pour les barres du burger menu */
.burger-menu .bar {
  width: 100%;
  height: 5px; /* Largeur des traits */
  background-color: #dfd9d9; /* Couleur des traits */
  border-radius: 5px; /* Arrondir les coins des traits */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Transition pour l'animation */
}

/* Animation pour transformer les barres en croix lorsque le drawer est ouvert */
.burger-menu.open .bar:nth-child(1) {
  transform: translateY(10px) rotate(45deg); /* Déplace et tourne la première barre */
  background-color: #2d3245; /* Couleur des traits */
}

.burger-menu.open .bar:nth-child(2) {
  opacity: 0; /* Cache la deuxième barre */
}

.burger-menu.open .bar:nth-child(3) {
  transform: translateY(-13px) rotate(-45deg); /* Déplace et tourne la troisième barre */
  background-color: #2d3245; /* Couleur des traits */
}

/* Contenu principal */
.main-content {
  margin-left: 225px; /* Respecte la largeur de la sidebar */
  flex: 1;
  background: white;
}

/* Appliquer une marge supérieure uniquement sur la page d'accueil */
.main-content.homepage {
  margin-left: 225px; /* Respecte la largeur de la sidebar */
  flex: 1;
  background: white;
  margin-top: 400px; /* Ajoute un espace sous le slider pour le contenu principal */
}

.logo-container {
  width: 100%;
  background-color: #fff;
  display: flex;
}

.logo {
  max-width: 100%;
  margin: auto;
}

/* Navigation */
.navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.navbar ul li a {
  padding: 10px 0;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2d3245;
  border-bottom: 1px solid #bfbfbf;
  height: 40px;
  font-size: 16px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.navbar ul li a:hover {
  color: #ffffff; /* Texte blanc pour contraste */
  background-color: #219446; /* Fond vert pour l'élément actif */
}

.navbar ul li a {
  text-decoration: none;
  color: inherit; /* Utiliser la couleur de l'élément li */
}

.navbar ul li a.active {
  color: #ffffff; /* Texte blanc pour l'élément actif */
  background-color: #219446; /* Fond vert pour l'élément actif */
}

.navbar .first {
  border-top: 1px solid #bfbfbf;
}

.contact-info {
  margin-top: 20px;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-width: 205px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #219446;
  padding: 10px;
  border-top: 1px solid #bfbfbf;  
}

.contact-info span, .contact-info p {
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

/* Styles Footer */
.footer {
  background-color: #1d1d1d;
  color: #fff;
  padding: 40px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 225px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

.footer-logo {
  margin-bottom: 10px;
}

.footer-logo-img {
  max-width: 150px;
  filter: grayscale(1);
}

.footer-links {
  margin-bottom: 20px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

.footer-links ul li {
  margin: 0;
}

.footer-links ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.footer-links ul li a:hover {
  color: #219446;
}

.footer-contact {
  text-align: center;
}

.footer-contact p {
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer-contact img {
  vertical-align: middle;
}

.footer-bottom {
  background-color: #1d1d1d;
  padding: 10px 20px;
  text-align: center;
  width: 100%;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
}

/* Réactif */
@media (max-width: 768px) {
  .burger-menu {
    display: flex; /* Afficher le burger menu en dessous de 768px */
  }

  .sidebar {
    transform: translateX(-225px); /* Cacher la sidebar en dehors de l'écran */
  }

  .sidebar.open {
    transform: translateX(0); /* Montrer la sidebar */
  }

  .main-content {
    margin-left: 0; /* Supprimer la marge pour mobile */
  }

  .main-content.homepage {
    margin-left: 0;
    margin-top: 0;
    padding-top: 375px;
  }

  .main-content.shifted {
    margin-left: 0; /* Assurez-vous que le contenu reste à gauche */
  }

  .footer {
    margin-left: 0;
  }

  .footer-content {
    padding: 20px;
  }

  .footer-links ul {
    flex-direction: column;
    align-items: center;
  }

  .footer-links ul li {
    margin-bottom: 10px;
  }

  .footer-logo-img {
    max-width: 120px;
  }
}
